import Container from "react-bootstrap/Container";
import {Form, json, useActionData, useLocation, useOutletContext, useSearchParams} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {Stack, Toast, ToastBody, ToastContainer} from "react-bootstrap";
import {useState} from "react";


export const ModifyAppointment = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let context = useOutletContext()

    return (
        <Container className={"d-flex bg-light flex-wrap justify-content-start rounded-3 p-3"}>
            <SuccessToast show={searchParams.get("rescheduled") === "true"}/>
            <h2 className={"w-100 flex-shrink-0 "}>Modify Appointment</h2>
            <Form method={"get"} action={"reschedule"}>
                <Stack className={"justify-content-start"}>
                    <Button className={"w-auto me-auto"} type={"submit"} disabled={!context.canReschedule}>Reschedule</Button>
                    <small>Appointment may not be rescheduled less than 48 hours in advance.</small>
                    <small>May only be rescheduled twice.</small>
                </Stack>
            </Form>
        </Container>

    );
}

export const SuccessToast = ({show = false}) => {
    const [showSuccess, setShowSuccess] = useState(show);

    return (
        <ToastContainer
            className={"w-100 position-fixed fixed-top"}
            position={"top-center"}>

            <Toast
                className={"w-100 bg-success text-light"}
                onClose={() => setShowSuccess(false)}
                show={showSuccess}

                delay={5000}
                autohide>
                <ToastBody className={"text-center"}>
                    Appointment rescheduled
                </ToastBody>
            </Toast>
        </ToastContainer>
    );
}