import {doJsonGET} from "../../util/FetchUtils";
import {Outlet, useLoaderData} from "react-router-dom";
import Container from "react-bootstrap/Container";
import React, {Alert, Col, Row, Stack} from "react-bootstrap";
import ImageGallery from "react-image-gallery";
// import {ReactComponent as AlertIcon} from "../../../assets/images/icons/plant_pot_green.svg"


export const clientAppointmentLoader = async ({params, request}) => {
    const response = await doJsonGET(`v2/appointments/${params.appointmentId}?withImages=true`);
    if (response.ok) {
        return await response.json();
    } else {
        throw response
    }
}

const extractFilename = (url) => {
    let splitArr = url.split('?');
    let stuff = splitArr[0].split('/');
    return stuff[stuff.length - 1];
}

export const processImages = (imageLinks) => {
    return imageLinks.map((link, index) => {
        let fileName = extractFilename(link)
        return {
            original: link,
            thumbnail: link,
            originalTitle: fileName,
            thumbnailTitle: fileName,
        };
    })
}

export const ClientAppointment = () => {
    const loaderData = useLoaderData();

    return (
        <Container className="bg-success d-flex flex-column column-gap-3 row-gap-3 rounded-3 mb-3 py-3">
            <Container className={"d-grid bg-light rounded-3"}>
                <Row>
                    <Col><h2>Appointment</h2></Col>
                </Row>
                <Row xs={2}>
                    <Col>Start:</Col><Col> {loaderData.start}</Col>
                    <Col>Length:</Col><Col>{loaderData.tattooMinutes} minutes</Col>
                    <Col>Estimated Cost:</Col><Col>${loaderData.cost}</Col>
                    <Col>Deposit Paid:</Col><Col>${loaderData.deposit}</Col>
                </Row>
                <Row>
                    <Col>
                        <small>* Length is estimated from the information provided. Actual time may vary.</small>
                    </Col>
                </Row>
            </Container>

            <Outlet context={{canReschedule: loaderData.canReschedule, maxRescheduleCount: loaderData.maxRescheduleCount, rescheduleCount: loaderData.rescheduleCount}}/>

            {loaderData.clients.map((client, index) => (
                <Container key={client.id + client.lastName}
                           className={"flex-column bg-light rounded-3"}>
                    <Container

                        className={"d-grid bg-light rounded-3"}>
                        <Row>
                            <Col><h2>Client {index + 1}</h2></Col>
                        </Row>
                        <Row xs={2}>
                            <Col>Name:</Col><Col>{client.firstName} {client.lastName}</Col>
                            <Col>Preferred Name:</Col><Col>{client.preferredName}</Col>
                            <Col>Pronouns:</Col><Col>{client.pronouns}</Col>
                            <Col>Email:</Col><Col>{client.email}</Col>
                            <Col>Phone:</Col><Col>{client.phone}</Col>
                            <Col>Allergies:</Col><Col>{client.allergies}</Col>
                            <Col>Accommodataions:</Col><Col>{client.accommodations}</Col>
                            <Col>First Tattoo:</Col><Col>{client.firstTattoo ? "Yes" : "No"}</Col>
                        </Row>
                    </Container>
                    {client.tattoos.map((tattoo, tindex) => (
                        <Container
                            key={tattoo.id + tattoo.type}
                            className={"d-grid bg-light rounded-3"}>
                            <Row>
                                <Col><h3>Tattoo {tindex + 1}</h3></Col>
                            </Row>
                            <Row xs={2}>
                                <Col>Type:</Col><Col>{tattoo.type}</Col>
                                {tattoo.description && <><Col>Description:</Col><Col>{tattoo.description}</Col></>}
                                {tattoo.placement && <><Col>Placement:</Col><Col>{tattoo.placement}</Col></>}
                                {tattoo.style && <><Col>Style:</Col><Col>{tattoo.style}</Col></>}
                                {tattoo.length && <><Col>Length:</Col><Col>{tattoo.length}</Col></>}
                                {tattoo.width && <><Col>Width:</Col><Col>{tattoo.width}</Col></>}
                            </Row>
                            {tattoo.imageLinks && tattoo.imageLinks.length > 0 &&
                                <Row xs={1}>
                                    <Col>

                                        <h4>Images</h4>
                                        <ImageGallery
                                            items={processImages(tattoo.imageLinks)}
                                            showPlayButton={false}
                                            useWindowKeyDown={false}
                                        />
                                    </Col>
                                    <Col>
                                        <ul>
                                            {processImages(tattoo.imageLinks).map((image, iidx) =>
                                                <li key={"image" + tindex + iidx}>
                                                    <a href={image.original}
                                                       key={image.original}>{"image"+iidx}</a>
                                                </li>
                                            )}
                                        </ul>
                                    </Col>

                                </Row>
                            }
                        </Container>
                    ))}
                </Container>
            ))}
        </Container>
    )
        ;

}