import AppointmentScheduleV2 from "../../forms/createV2/AppointmentScheduleV2";
import Button from "react-bootstrap/Button";
import {Form, json, Navigate, redirect, useOutletContext} from "react-router-dom";
import React from "react";

export const rescheduleAction = async ({params, request}) => {
    console.log("reschedule action hit")
    let jsonData = await request.json();
    try {
        let response = await fetch(`https://botanical.ink/api/v2/appointments/${params.appointmentId}/schedule`, {
            method: "post",
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (response.ok) {
            return redirect("..?rescheduled=true")
        } else if (response.status === 422) {
            // validation issue
            return await response.json();
        } else {
            return json({
                error: response.status,
                message: "Something happened..."
            });
        }
    } catch (error) {
        console.log(error)
        return json({
            error: '500',
            message: "An error occurred while making a request to the server"
        });
    }
}

export const Reschedule = () => {
    let context = useOutletContext()

    if (!context.canReschedule) {
        return (
          <Navigate to={".."} replace={true} />
        );
    }

    return (
        <>
            <AppointmentScheduleV2/>
            <Form method={"get"} action={".."} className={"d-flex justify-content-center"}>
                <Button type={"submit"} className={"w-50 mx-auto"}
                        style={{backgroundColor: "grey"}}>Cancel</Button>
            </Form>

        </>
    );

}